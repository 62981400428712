import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  imageScontrino: string | ArrayBuffer;
  fotoScontrino: File;
  public files: NgxFileDropEntry[] = [];
  public filesTable:any[]=[];

  @Input() maxFiles:number=1;
  @Output() change = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  reset(){
    this.filesTable=[];
    this.change.emit(this.filesTable);
  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  showBrowse() {
    return this.filesTable.length<this.maxFiles
  }
  humanFileSize(size) {
    var i = Math.floor( Math.log(size) / Math.log(1024) );
    var n:any=( size / Math.pow(1024, i));
    return n.toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  } ;
  removeFile(x) {
    const index = this.filesTable.indexOf(x);
    if (index > -1) {
      this.filesTable.splice(index, 1);


    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    const remain=this.maxFiles-this.filesTable.length;
    files=files.slice(0,remain);
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fotoScontrino=file;
          // Here you can access the real file
          var reader = new FileReader();
          reader.addEventListener("load",  ()=> {
            // convert image file to base64 string
           let obj= {
            'lastModified'     : file.lastModified,
            'name'             : file.name,
            'size'             : file.size,
            'type'             : file.type,
            'base64'           : reader.result
         };

          this.filesTable.push(obj)
          this.change.emit(this.filesTable);
          }, false);
          reader.readAsDataURL(file);


        });
      }
    }
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }
}
