import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { DatePipe } from '@angular/common'
import { EnvService } from './env.service';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient, private env: EnvService,public datepipe:DatePipe) { }

  get apiUrl(): string {
    return this.env.apiUrl;
  }

    getCard(nameClass: string)
  {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiUrl}/class/${nameClass}/cards`;
    return this.http.get(url);
  }

  postDir(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${endpoint}`, data, options);
  }

  deleteDir(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
  }

  getCardFiltered(nameClass: string,filter:any)
  {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiUrl}/class/${nameClass}/cards${filter}`;
    return this.http.get(url);
  }

  getDataSourceDevExp(
    nameClass: string,
    baseFilter: any,
    isTree: boolean = false,
    treeRoot = null,
    sortField=null,
    getAll:boolean=false
  ) {
    nameClass = nameClass.replace(/"/g, '');
    let url = `${this.apiUrl}/class/${nameClass}/cardsExp`;
    const urlUpdate = `${this.apiUrl}/${nameClass}/cardsExpUpdate`;
    const urlDelete = `${this.apiUrl}/${nameClass}/cards`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions) => {
        if (getAll) {
          loadOptions.skip=0;
          loadOptions.take=1000;
        }
        if (baseFilter) {
          const baseFilterC = this.calculateExpression(
            baseFilter,
            this.baseFunction(),
          );

          if (loadOptions.filter) {
            loadOptions.filter = [baseFilterC, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilterC;
          }
        }
        
        if (!loadOptions.sort) {
          if (sortField) {
            loadOptions.sort = [{ selector: sortField, desc: false },{ selector: 'Description', desc: false }];
          } else {
            // ORDINAMENTO DI DEFAULT
            loadOptions.sort = [{ selector: 'Description', desc: false }];

          }

        }
        const queryParams = [];
        if (treeRoot) {
          queryParams.push('treeRoot=' + treeRoot);
        }
        if (isTree) queryParams.push('isTree=true');

        const queryParamString = queryParams.join('&');

        url = url + (queryParamString ? '?' + queryParamString : '');
        return this
          .postDir(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      update: (key, values) => {
        const params = {
          key: key,
          values: values,
        };
        return this
          .postDir(urlUpdate, params)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      remove: (key) => {
        return this
          .deleteDir(`${urlDelete}/${key}/`)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  calculateExpression(f, o) {
    'use strict';
    if (!f) return null;
    const copy = [...f];
    const m = o;
    copy.forEach((element, index) => {
      try {
        if (Array.isArray(element)) {
          copy[index] = this.calculateExpression(element, m);
        } else {
          copy[index] = eval(element);
        }
      } catch (error) {}
    });

    return copy;
  }

  
  baseFunction() {
    return {
      today: moment().format('YYYY-MM-DD'),
    };
  }

  get(endpoint: string, options?): Observable<any> {

    return this.http.get(`${this.apiUrl}/${endpoint}`, options);
  }

  post(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${this.apiUrl}/${endpoint}`, data, options);
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiUrl}/${endpoint}`, data, options);
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
  }


  addClassAllegato(objId: number, idFile: number, code: string, description: string,  file: any): Observable<any> {
    const formData: any = new FormData();

    var date = moment().format("YYYY-MM-DD");
    var scadenza =moment().add(2,"years").format("YYYY-MM-DD");
    formData.append('idFile', idFile ? idFile : 0);
    formData.append('code', code);
    formData.append('description', description);
    formData.append('categoria', "FREE");
    formData.append('DataRiferimento',date);
    formData.append('DataScadenza',scadenza);

    if (file ) {
      formData.append('fileName', file.File.Name);
      formData.append('fileSize', file.File.Size);
      formData.append('file64', file.File.Base64);
      return this.http.post(`${this.apiUrl}/class/uploadAllegato/${objId}`, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        catchError(this.errorMgmt),
      );

    }
  }
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `${error.message}`;
    }

    return throwError(errorMessage);
  }
}
