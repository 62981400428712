import { ChangeDetectorRef, Component, Directive, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/api.service';
import { UserStore } from 'src/app/core/services/user.store';
import { isJSDocThisTag } from 'typescript';
import { DatePipe } from '@angular/common'
import { MappaComponent } from '../mappa/mappa.component';


export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: any;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: any = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();


  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}

@Component({
  selector: 'app-tue-segnalazione',
  templateUrl: './tue-segnalazione.component.html',
  styleUrls: ['./tue-segnalazione.component.css']
})
export class TueSegnalazioneComponent {

  spinner:boolean=true;
  user:any;
  segnalazioni1: any;
  settori:any;
  testo:any;
  mapPoint:any;
  categoria:any;
  stato:any;
  startDate:any;
  endDate:any;
  body:any;
  filter=[]
  tooltip;
  icone:{};
  detail:any;
  segnalazioniOriginali: any;
  ok: boolean;
sd

  @ViewChild(MappaComponent) mappa: MappaComponent; 
  constructor(protected cd: ChangeDetectorRef,protected router: Router, public datepipe: DatePipe,private service: HttpService, private userStore: UserStore) { }

  ngOnInit(): void {
   
    this.user=this.userStore.getUser();
    this.filter=[["SegnalatoreEmail","=",this.user.email]]
    console.log(this.user)
    var store=this.service.getDataSourceDevExp("Segnalazioni",this.filter,false,null,null,true);
    store.on("loading",this.loading.bind(this));
    store.on("loaded",this.loaded.bind(this));
    store.load();
    this.service.getCard("Segnalazioni_Settori").subscribe((res:any)=>{
      this.settori=res.data.list;
    });

  }
  loading(res){
    console.log("START LOADING....")
    this.spinner=true;
  }
  loaded(res)
  {
    console.log(res)
    this.spinner=false;
    console.log("END LOADING....")
    res.data.forEach(element => {
      if (element.Conclusa) {
        element.stato="Risolta"
        element.tooltip="L'intervento richiesto con la segnalazione è stato effettuato"
        element.icona="fas fa-check"
      } else if(element.TipoEsecutore!=null){
        element.stato="In lavorazione"
        element.tooltip="Sono avviati i lavori per la segnalazione"
        element.icona="fas fa-cogs"
      }
      else {
        if (element.Valid===null) {
          element.stato="Inserita"
          element.tooltip="L'intervento è in fase di accettazione"
          element.icona="fas fa-folder"
        
        } else {
          element.stato= element.Valid?"Accettata":"Non di competenza"
          element.tooltip= element.Valid? "La segnalazione è stata verificata e considerata valida.A breve sarà assegnata ad un gestore per l'esecuzione dei lavori.":"La segnalazione è stata chiusa in quanto non rientrante nelle tipologie presenti nel portale ed è stato aperto un reclamo nel sistem interno dell'URP"  
          element.icona=element.Valid? "fas fa-thumbs-up":"fas fa-ban"
        }
      }
    });
    this.segnalazioni1=res.data;
    this.segnalazioniOriginali=res.data;
  }


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction !== '' && column !== '') {
      this.segnalazioni1 = [...this.segnalazioni1].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  redirect()
  {
    this.router.navigateByUrl("/new-segnalazione");
  }

  
  groupByFn(item) { 
    return item.Gruppo
  }

  setDetail(seg?:any)
  {
    this.ok=false;
    this.detail=seg;
    this.mapPoint = {
      name: this.detail.Indirizzo,
      latitude: this.detail.Latitudine,
      longitude: this.detail.Longitudine,

    }
    this.ok=true;    
    this.cd.detectChanges();
    setTimeout(() => {
      this.mappa.refresh();
    }, 100);
  }

  
  filtraggio()
  {
    this.segnalazioni1=this.segnalazioniOriginali;
    if (this.categoria?.Id) {
      this.segnalazioni1=this.segnalazioni1.filter(x=>x._Categoria_Id==this.categoria.Id);
    }
    if(this.testo)
    {
      this.segnalazioni1=this.segnalazioni1.filter(x=>
        {
          return (x.Descrizione.toLowerCase().indexOf(this.testo.toLowerCase()) >-1);
        });
    }
    if(this.stato)
    {
      this.segnalazioni1=this.segnalazioni1.filter(x=>x.stato==this.stato);
    }
    if(this.startDate)
    {
      
      this.segnalazioni1=this.segnalazioni1.filter(x=>x.DataSegnalazione>this.startDate);
    } 
    if(this.endDate)
    {
      
      this.segnalazioni1=this.segnalazioni1.filter(x=>x.DataSegnalazione<this.endDate); 
    }
   }

   azzera()
   {
     this.categoria=""
     this.testo=""
     this.stato=""
     this.startDate=""
     this.endDate=""
     this.ngOnInit()
   }
  
}
