<div class="container">
    <div class="card-space">
        <div class="card card-bg">
            <div class="card-body">
                <h4 class="card-title">Nuova Segnalazione</h4>


                <form [formGroup]="segnalazioneForm" #formReg="ngForm">
                    <div class="cross-validation"
                        [class.cross-validation-error]="segnalazioneForm.errors && (segnalazioneForm.touched || segnalazioneForm.dirty)">
                        <div class="row" style="margin-top: 50px;margin-bottom:10px">
                            <div class="col-lg-6 col-md-12 ">
                                <div class="form-group " style="margin-bottom: 0px;">
                                    <label class="active">Categoria</label>
                                    <ng-select [groupBy]="groupByFn" formControlName="categoria">
                                        <ng-option *ngFor="let settore of settori" [value]="settore">
                                            {{settore.Description}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <textarea formControlName="testo" style="height: 460px;" rows="14"
                                        placeholder="Inserisci il testo della segnalazione" id="testo"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 ">

                                <div class="form-group" style="margin-bottom: 0px;">
                                    <label class="active">Cerca un indirizzo o seleziona un punto sulla mappa</label>
                                    <app-mappa [mapPoint]="mapPoint" (locationSelected)="setAddress($event)">
                                    </app-mappa> <br>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <label class="active">Indirizzo:</label>
                                        <input class="form-control" formControlName="luogo" type="text"
                                            id="input-text-read-only" readonly style="font-size:14px">
                                    </div>
                                    <div class="col-4">
                                        <label class="active">Civico:</label>
                                        <input class="form-control" formControlName="civico" type="text" id="input-text"
                                            style="font-size:14px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <input type="file" (change)="onFileChange($event)" name="upload1" id="upload1"
                                class="upload" multiple="multiple" />
                            <label for="upload1">
                                <svg class="icon icon-sm" aria-hidden="true">
                                    <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-plus-circle"></use>
                                </svg>
                                <span>Immagini &nbsp;</span>
                            </label>
                            <table class="table table-striped">
                                <tbody>
                                    <tr *ngFor="let item of allegati">
                                        <td>{{ item.File.Name }}</td>
                                        <td width="32">
                                            <button type="button" class="btn p-0" (click)="removeFile(item)">
                                                <svg class="icon icon-sm" aria-hidden="true">
                                                    <use
                                                        xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-minus-circle">
                                                    </use>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-5 col-md-12">
                            <div class="form-check ">
                                <input id="checkbox1" (change)="checkValue()" type="checkbox">
                                <label for="checkbox1">
                                    Accettazione informativa sui Dati personali
                                </label>
                            </div>

                        </div>
                        <div class="col-12">
                            <p class="form-text">
                                Procedendo con la compilazione e con l'invio di questo modulo Lei acconsente al
                                trattamento
                                dei
                                dati personali forniti, ai sensi e per gli effetti dell'articolo 13 del Regolamento EU
                                2016/679
                                e della della normativa nazionale applicabile in materia di trattamento dei dati
                                personali,
                                per
                                la finalità e nei limiti indicati dalla informativa privacy che la invitiamo a leggere
                                con
                                attenzione.</p>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="form-group col">
                            <button type="button" class="btn btn-primary" [disabled]="formInvalid() || !isOk"
                                (click)="formSubmit()">Invia Segnalazione</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>