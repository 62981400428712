import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { HttpService } from 'src/app/core/services/api.service';
import { locationService } from 'src/app/core/services/location.service';
import { UserStore } from 'src/app/core/services/user.store';


@Component({
  selector: 'app-new-segnalazione',
  templateUrl: './new-segnalazione.component.html',
  styleUrls: ['./new-segnalazione.component.css']
})
export class NewSegnalazioneComponent implements OnInit {


  mapPoint: any;
  segnalazioneForm: FormGroup;
  body: any;
  isOk: boolean = false;
  listaFile: any = [];
  myLat: any;
  myLong: any;
  settori:any;
  indirizzo:any;
  allegati:any = [];
  user:any;

  constructor(protected cd: ChangeDetectorRef, private location: locationService,private userStore: UserStore,private classService:HttpService,protected router: Router) {
    this.location.getPosition().then((pos: any) => {

      this.mapPoint = {
        name: null,
        latitude: pos.Lat,
        longitude: pos.Long,
        createMarker:true
      }

    });


    this.segnalazioneForm = new FormGroup({
      categoria: new FormControl('', [Validators.required]),
      testo: new FormControl('', [Validators.required]),
      luogo: new FormControl('', [Validators.required]),
      civico: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.user=this.userStore.getUser();
    console.log(this.user)
    this.classService.getCard("Segnalazioni_Settori").subscribe((res:any)=>{
      this.settori=res.data.list;
    });
  }


  get testo() { return this.segnalazioneForm.get('testo')!; }
  get categoria() { return this.segnalazioneForm.get('categoria')!; }
  get luogo() { return this.segnalazioneForm.get('luogo')!; }
  get civico() { return this.segnalazioneForm.get('civico')!; }

  formSubmit() {
    var req = [];
    var oggi=new Date();
    this.body = {
      Descrizione: this.testo.value,
      Categoria: this.categoria.value.Id,
      Indirizzo: this.luogo.value+(this.civico.value?', '+this.civico.value:''),
      Civico:this.civico.value,
      Latitudine: parseFloat(this.indirizzo.latitude),
      Longitudine:parseFloat(this.indirizzo.longitude),
      SegnalatoreEmail:this.user.email,
      SegnalatoreNome:this.user.firstName,
      SegnalatoreCognome:this.user.lastName,
      DataSegnalazione:oggi
    }
    console.log(this.body)
    this.classService.post("class/Segnalazioni/cards",this.body).subscribe((res:any)=>{
      this.allegati.forEach(allegato => {
        req.push(this.classService.addClassAllegato(res.data.Id,0,"FREE","Allegato segnalazione",allegato))
      });
      if(req.length > 0) {
        forkJoin(req).subscribe((res:any)=>{
          this.router.navigateByUrl("/home");
        });
      } else this.router.navigateByUrl("/home");
    });

  }

  formInvalid() {
    return this.segnalazioneForm.invalid
  }
  checkValue() {
    this.isOk = !this.isOk;
  }

  removeFile(x) {
    const index = this.allegati.indexOf(x);
    if (index > -1) {
      this.allegati.splice(index, 1);

    }
  }

  onFileChange(e) {
    var file = e.srcElement.files[0];
    console.log(file);
    var value:any;
    var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          value = {
            File: {
              IdFile: -1,
              Name: file.name,
              Size: file.size,
              FilePicked : { content: reader.result },
              Base64: reader.result
            }
          };
          this.allegati.push(value)
        }
  }
  setAddress(e) {
    console.log(e);
    this.segnalazioneForm.get('luogo').setValue(e.name)
    this.segnalazioneForm.get('civico').setValue(e.address.Civico)
    // if (e.address) {
    //   Object.keys(e.address).forEach((key) => {
    //     console.log(e.address[key]);
    //   });
    // }
    this.indirizzo=e;
    this.cd.markForCheck();
  }

  groupByFn(item) {
    return item.Gruppo
  }

}
