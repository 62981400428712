<!--tabella filtraggio-->
<div class="container-fluid" style="background-color: #f3f3f3;" >
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <h4 style="margin-bottom: 0px;margin-top:10px; font-weight: 100;">Filtra i risultati</h4>
        <div class="row">
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Categoria</label>
            <div class="form-group">
              <ng-select style="background-color: white;" [groupBy]="groupByFn" [(ngModel)]="categoria">
                <ng-option *ngFor="let settore of settori" [value]="settore">
                  {{settore.Description}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Testo:</label>
            <div class="form-group">
              <input [(ngModel)]="testo" type="text" class="form-control" id="formGroupExampleInput2">
            </div>
          </div>
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Stato:</label>
            <div class="form-group">
              <ng-select style="background-color: white;" [(ngModel)]="stato">
                <ng-option value="Risolta">Risolta</ng-option>
                <ng-option value="Accettata">Accettata</ng-option>
                <ng-option value="Inserita">Inserita</ng-option>
                <ng-option value="Non di competenza">Non di competenza</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <label class="active">Dal:</label>
            <div class="form-group">
              <input [(ngModel)]="startDate" style="font-weight: 100;" type="date" class="form-control"
                id="formGroupExampleInput2">
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <label data-toggle="tooltip" title="Per Davide" class="active">Al:</label>
            <div class="form-group">
              <input [(ngModel)]="endDate" style="font-weight: 100;" type="date" class="form-control"
                id="formGroupExampleInput2">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-lg-3">
            <button type="button" class="btn btn-secondary btn-block" (click)="azzera()">Azzera filtri</button>
          </div>
          <div class="col-4  col-lg-3">
            <button type="button" class="btn btn-primary  btn-block" (click)="filtraggio()">Filtra</button>
          </div>
        </div><br/>
      </div>
    </div>
  </div>
  <i class="fa-solid fa-user"></i>
</div>
<!--fine tabella filtraggio-->


<!-- spinner-->
<div class="container" *ngIf="spinner">
  <div class="row">
    <div class="col-4">
    </div>
    <div class="col-4" style="text-align: center;margin-top: 10px;">
      <div class="cover-progress d-flex align-items-center justify-content-center">
        <div class="progress-spinner progress-spinner-active">
          <span class="sr-only">Caricamento...</span>
        </div>
      </div>
    </div>
    <div class="col-4">
    </div>
  </div>
</div>
<!--chiusura spinner-->

<div class="container" *ngIf="!spinner && segnalazioni1.length==0" style="text-align: center;">
  <div class="row">
    <div class="col-12 text-center">
      <div class="callout danger">
        <div class="callout-title"><svg class="icon"><use href="/assets/bootstrap-italia/svg/sprite.svg#it-close-circle"></use></svg>Nessuna segnalazione inserita</div>
        <br>
        <p>Ancora non hai inserito nessuna segnalazione.<br></p>
      </div>
    </div>
  </div>
</div>


<!--tabella segnalazioni-->
<div class="container" *ngIf="!spinner && !(segnalazioni1.length==0)" style="margin-top:50px" >
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Data</th>
        <th scope="col" style="cursor: pointer;" sortable="_Categoria_Description" (sort)="onSort($event)">Categoria
        </th>
        <th class="d-none d-lg-table-cell" scope="col">Testo</th>
        <th scope="col">Indirizzo</th>

        <th scope="col">Dettagli</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let segnalazione of segnalazioni1">
        <td>{{segnalazione.DataSegnalazione | date:'dd/MM/yyyy'}}<br>
          <span style="cursor: pointer" ngbTooltip="{{segnalazione.tooltip}}"><i class="{{segnalazione.icona}}"></i>
            {{segnalazione.stato}}</span>
        </td>
        <td>{{ segnalazione._Categoria_Description }}</td>
        <td class="d-none d-lg-table-cell">{{ segnalazione.Descrizione }}</td>
        <td> {{segnalazione.Indirizzo}}</td>

        <td>
          <button data-toggle="modal" data-target="#exampleModal" type="button" (click)="setDetail(segnalazione)"
            class="btn ">
            <svg class="icon icon-sm" aria-hidden="true">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-info-circle"></use>
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--fine tabella segnalazioni-->




<div class="container">
  <button type="button" (click)="redirect()" style="margin-top: 50px;margin-bottom: 20px;" class="btn btn-danger">Clicca
    qui per inserire una nuova segnalazione</button>
</div>


<!-- Modal -->
<div  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="exampleModal" aria-labelledby="exampleModalTitle"
  *ngIf="detail">
  <div class="modal-dialog modal-xl modal-sm modal-dialog modal-dialog-centered"  role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalTitle">Dettaglio segnalazione<br><small>Ultimo aggiornamento: {{detail.BeginDate | date:'dd/MM/yyyy HH:mm' }}</small></h4>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Categoria:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail._Categoria_Description}}"
              aria-label="Label" disabled>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Gruppo:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail._Categoria_Gruppo}}"
              aria-label="Label" disabled>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6 col-md-12 col-sm-12" >
            <label class="active">Data:</label>
            <input class="form-control" id="disabledInput" type="text"
              placeholder="{{detail.DataSegnalazione | date:'dd/MM/yyyy' }}" aria-label="Label" disabled>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Stato:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail.stato}}" aria-label="Label"
              disabled>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <label class="active">Posizione:</label>
            <div class="form-group" style="width:100%;">
              <app-mappa [viewIndirizzo]="false" [mapPoint]="mapPoint"></app-mappa> <br>
              <input class="form-control" id="disabledInput" type="text" placeholder="{{detail.Indirizzo}}"
                aria-label="Label" disabled>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-sm" data-dismiss="modal" type="button">Chiudi</button>
        </div>
      </div>
    </div>
  </div>