export class locationService {
    constructor() {}

    getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({Long: resp.coords.longitude, Lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }
}