import { Injectable } from "@angular/core";
import { NbAuthStrategyClass, NbOAuth2AuthStrategy, NbOAuth2AuthStrategyOptions } from '@nebular/auth';
@Injectable()
export class googleOauth extends NbOAuth2AuthStrategy {

  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [googleOauth, options]; // HERE we make sure our strategy reutrn correct class reference
  }

}
