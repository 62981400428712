import { UserStore } from "./core/services/user.store";
import { Component, OnInit} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from "@angular/forms";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import {
  NbAuthJWTToken,
  NbAuthOAuth2JWTToken,
  NbAuthService,
} from "@nebular/auth";
import { HttpService } from "./core/services/api.service";
import { prepareSyntheticPropertyName } from "@angular/compiler/src/render3/util";
import { NgLoadingSpinnerTemplateDirective } from "@ng-select/ng-select/lib/ng-templates.directive";
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';




@Pipe({ name: 'noSanitize' })
export class NoSanitizePipe implements PipeTransform {
   constructor(private domSanitizer: DomSanitizer) {

   }
   transform(html: string): SafeHtml {
      return this.domSanitizer.bypassSecurityTrustHtml(html);
   }
}


@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "app";
  loading: boolean;
  parametri: any;
  user: any = {};

  constructor(
    private authService: NbAuthService,
    private userStore: UserStore,
    private apiService: HttpService,
    private sanitizer: DomSanitizer,
    private router: Router,
    protected httpClient: HttpClient,
  ) {



    this.user = null;
    this.userStore.loggedUserChange.subscribe(() => {
      this.user = this.userStore.getUser();
    });

    this.authService
      .onTokenChange()
      .subscribe((token: NbAuthOAuth2JWTToken) => {
        console.log("APP CHECK TOKEN");
        if (token.isValid()) {
          const payload = token.getPayload();
          var payloadAccessToken = [];
          try {
            payloadAccessToken = token.getAccessTokenPayload();

            this.userStore.setUser(
              payload.user ? payload.user : null,
              payloadAccessToken,
              token
            );
            this.apiService.get("users/current").subscribe((current) => {
              if (current.data.persona) {
                current.data.firstName = current.data.persona.Description;
                this.userStore.setUser(current.data, payloadAccessToken, token);
              }
            });
          } catch (error) { }
        } else {
          this.userStore.clearUser();
        }
      });
  }
  
  ngOnInit(): void {
    this.httpClient.get('assets/siteConfig.json').subscribe((res:any) => {
      this.parametri = res;
     
    });
  }

  login() {
    this.router.navigateByUrl('/auth/login');
  }

 
}
