import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

declare var google: any;

@Injectable()
export class GeocodeLeafletService {
  private geocoder: any;


  constructor(private api: HttpClient) {}


  addressLookup(req?: any): Observable<any>{
    let url = `https://nominatim.openstreetmap.org/search?format=json&q=${req}&bounded=1&addressdetails=1`;
    return this.api
      .get(url).pipe(
        map((data: any) => {
          return { ...data };
         }
        )
      )

  }

  reverseLookup(lat?: any, lon?:any): Observable<any>{
    let url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    return this.api
      .get(url).pipe(
        map((data: any) => {
          return { ...data };
         }
        )
      )

  }
}
