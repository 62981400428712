import { Component, OnInit } from '@angular/core';
import { UserStore } from '../../core/services/user.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  
  accedi: boolean = true;
  user: any;
 

  constructor(private userStore: UserStore, protected router: Router) { }

  ngOnInit() {
    this.userStore.loggedUserChange.subscribe(() => {
      this.user = this.userStore.getUser();
      console.log(this.user);
    });
  }


  redirectLogin()
  {
    this.router.navigateByUrl('/auth/login');
  }

}
