import { Injectable } from '@angular/core';
import { NbAuthOAuth2JWTToken } from '@nebular/auth';
import { BehaviorSubject } from 'rxjs';
import { User } from '../interfaces/users';


@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private user: User;
  private messageSource = new BehaviorSubject('default message');
  loggedUserChange = this.messageSource.asObservable();

  constructor() { }

  getUser(): User {
    this.user=JSON.parse(localStorage.getItem('loggedUser'))
    return this.user;
  }

  setUser(paramUser: any,payloadAccessToken:any,token:any) {
    if (!paramUser) return;
    if (paramUser) {
      let access_token=null;
      if (token.ownerStrategyName=="email") {
        access_token=token.token.access_token;
      }

      var roleSelected=payloadAccessToken['roleSelected']?JSON.parse(payloadAccessToken['roleSelected']):null;
      this.user = {
        id:paramUser.id,
        email:paramUser.email,
        firstName:paramUser.firstName,
        lastName:paramUser.lastName,
        name:paramUser.firstName+(paramUser.lastName?' '+paramUser.lastName:''),
        picture:paramUser.picture,
        role:roleSelected?roleSelected.Code :'user',
        roleDescription:roleSelected?roleSelected.Description:'',
        access_token:access_token,
        persona:paramUser.persona
      };
    }

    localStorage.setItem('loggedUser',JSON.stringify(this.user));
    this.messageSource.next("changeLoggedUser")
  }

  setUserGoogle(googleUser: any) {
    this.user = {
      id:googleUser.id,
      email:googleUser.email,
      firstName:googleUser.family_name,
      lastName:googleUser.given_name,
      name:googleUser.name,
      picture:googleUser.picture,
      role:'user',
      roleDescription:'',
      access_token:null
    }
    localStorage.setItem('loggedUser',JSON.stringify(this.user));
    this.messageSource.next("changeLoggedUser")
  }
  clearUser(){
    localStorage.removeItem('loggedUser');
    this.messageSource.next("changeLoggedUser")
  }

  setUserFacebook(facebookUser: any) {
    this.user = {
      id: facebookUser.id,
      email:facebookUser.email,
      firstName:facebookUser.first_name,
      lastName:facebookUser.last_name,
      name:facebookUser.name,
      picture:facebookUser.picture,
      role:'user',
      roleDescription:'',
      access_token:null
    }
    localStorage.setItem('loggedUser',JSON.stringify(this.user));
    this.messageSource.next("changeLoggedUser")
  }
}
