<div *ngIf="showBrowse()">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)" [multiple]="false" [directory]="false" accept=".png,.jpg,.jpeg">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="text-center"> Trascina e rilascia qui il tuo documento<br />
        --- OPPURE --- <br>
        <button class="btn btn-primary btn-sm" type="button" (click)="openFileSelector()">Clicca qui </button>
      </div>

    </ng-template>
  </ngx-file-drop>
</div>


<div *ngIf="filesTable && filesTable.length>0" style="max-height:200px">
  <br>
  <div class="upload-table">
    <table class="table"
      style="background-color: #fff;border-left: 5px solid #42A948;border-radius: 0.25rem !important;">
      <tbody class="upload-name-style">
        <tr *ngFor="let item of filesTable; let i=index">
          <td>{{item.name}}</td>
          <td style="width: 150px;">{{humanFileSize(item.size)}}</td>
          <td width="32">
            <button type="button" class="btn p-0" (click)="removeFile(item)">
              <i class="text-danger fas fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>