// http.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { UserStore } from './services/user.store';
import { HttpService } from './services/api.service';

@Injectable()
export class MyHttpLogInterceptor implements HttpInterceptor {
  urlsToNotUse: string[];
  constructor(private userStore:UserStore,private apiService:HttpService) {
    this.urlsToNotUse= [
      'auth/login',
      'auth/logout',
      'auth/loginSPID'
    ];
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isValidRequestForInterceptor(request.url)) {
      return next.handle(request);
    }
    console.log(`INTERCEPT ${request.url}`);
    const user = this.userStore.getUser();

    if (user && user.access_token) {
      const cloned = request.clone({
        headers: request.headers.set("Authorization",
          "Bearer " + user.access_token)
      });

      return next.handle(cloned);
    }
    else {
      return from(this.handle(request, next))

    }

  }
  private isValidRequestForInterceptor(requestUrl: string): boolean {



    let positionIndicator  = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    var data = {
      "email": "segnalazioni@segnalazioni.segnalazioni",
      "password": "12345"
    }
    const token=await this.apiService.post("auth/login",data,{headers:{skip:"true"}}).toPromise();
    const cloned = req.clone({
      headers: req.headers.set("Authorization",
        "Bearer " + token.token.access_token)
    });

    return next.handle(cloned).toPromise();
  }

}
