import { fromEvent, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  Directive, Component, ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';

import * as L from 'leaflet';
import { GeocodeLeafletService } from 'src/app/core/services/geocode.Leaflet.service';
import { HttpService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-script',
  templateUrl: './script.component.html',
  styleUrls: ['./script.component.css']
})
export class ScriptComponent implements OnInit {
  rivenditori:any = [];
  constructor(private leafletService:GeocodeLeafletService,private httpService:HttpService) { }

  ngOnInit() {
    this.httpService.getCard("Rivenditori").subscribe((resRiv:any)=>{
      this.rivenditori = resRiv.data.list;
      this.rivenditori.forEach((riv:any,i:number)=> {

        
        setTimeout(() => {
          if(riv.Indirizzo) {
          var indirizzo = riv.Indirizzo + ","+ riv.Citta + "," + riv.Provincia
          console.log("operazione numero: "+i + " per ");
          this.leafletService.addressLookup(indirizzo).subscribe((resNominatim:any)=>{
            console.log(resNominatim);
          });
        } else console.log("Indirizzo non presente nella tabella")
        }, i * 1500);
      });
    });
  }


}
