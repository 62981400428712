import { MyHttpLogInterceptor } from './core/http.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { facebookOauth } from './core/facebookStrategie';
import { googleOauth } from './core/googleStrategie';
import { rivenditoreStrategie } from './core/rivenditoreStrategie';
import { clientStrategie } from './core/clientStrategie';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
// modules ...
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbSpinnerModule, NbCardModule, NbUserModule, NbButtonModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth-guard.service';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormsModule } from '@angular/forms';
import { NoSanitizePipe } from './app.component';
import { DatePipe } from '@angular/common'
//import { NgQRCodeReaderModule } from 'ng2-qrcode-reader';
import { NbIconModule, NbFormFieldModule } from '@nebular/theme';
// components ...
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DelayedInputDirective } from './pages/mappa/mappa.component';

import { NbAuthOAuth2JWTToken, NbPasswordAuthStrategy, NbAuthModule, NbOAuth2AuthStrategy, NbOAuth2ResponseType, NbAuthService } from '@nebular/auth';
import { HttpService } from './core/services/api.service';
import { EnvService } from './core/services/env.service';
import { EnvServiceProvider } from './core/services/env.service.provider';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { locationService } from './core/services/location.service';

import { NgxFileDropModule } from 'ngx-file-drop';
import { UploaderComponent } from './core/uploader/uploader.component';
import { NgbdCarouselBasic } from './pages/test/carousel-basic';

import { RegisterComponent } from './auth/register/register.component';
import { GeocodeLeafletService } from './core/services/geocode.Leaflet.service';
import { ScriptComponent } from './pages/test/script/script.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NewSegnalazioneComponent } from './pages/new-segnalazione/new-segnalazione.component';
import { NgbdSortableHeader, TueSegnalazioneComponent } from './pages/tue-segnalazione/tue-segnalazione.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MappaComponent } from './pages/mappa/mappa.component';
import { AccessibilitaComponent } from './pages/accessibilita/accessibilita.component';
import { spidStrategie } from './core/spidStrategie';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UploaderComponent,
    NgbdCarouselBasic,
    RegisterComponent,
    ScriptComponent,
    NewSegnalazioneComponent,
    TueSegnalazioneComponent,
    NgbdSortableHeader,
    DelayedInputDirective,
    MappaComponent,
    NoSanitizePipe,
    AccessibilitaComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    LeafletModule,
    ReactiveFormsModule,
    NbIconModule,
    NgSelectModule,
    NgxSpinnerModule,
    NbEvaIconsModule,
    NbFormFieldModule,
    NbButtonModule,
    ZXingScannerModule,
    NbUserModule,
    IvyCarouselModule,
    NbAuthModule.forRoot({
      strategies: [
        rivenditoreStrategie.setup({
          name: 'email',
          token: {
            class: NbAuthOAuth2JWTToken,
            key: 'token'
          },
          baseEndpoint: 'fake',  //url base
          login: {
            endpoint: '/auth/login',  //chiamata al singolo servizio
            method: 'post',      //specificare medoto DEFAULT GET
            redirect: {
              success: 'home', // welcome page path
              failure: null, // stay on the same page
            },
          },
          logout: {
            method: null,
            redirect: {
              success: '/home', // welcome page path
              failure: null, // stay on the same page
            }
          },
          register: {
            // ...
            endpoint: '/auth/register',  //chiamata al singolo servizio
            redirect: {
              success: '/auth/login',
              failure: null, // stay on the same page
            },
          },
        }),
        clientStrategie.setup({
          name: 'otp',
          token: {
            class: NbAuthOAuth2JWTToken,
            key: 'token'
          },
          baseEndpoint: 'fake',  //url base
          login: {
            endpoint: '/auth/loginOTP',  //chiamata al singolo servizio
            method: 'post',      //specificare medoto DEFAULT GET
            redirect: {
              success: 'home', // welcome page path
              failure: null, // stay on the same page
            },
          },
          logout: {
            method: null,
            redirect: {
              success: '/home', // welcome page path
              failure: null, // stay on the same page
            }
          },
          register: {
            // ...
            endpoint: '/auth/register',  //chiamata al singolo servizio
            redirect: {
              success: '/auth/login',
              failure: null, // stay on the same page
            },
          },
        }),
        googleOauth.setup({
          name: 'google',
          clientId: '147735742099-6oj8946iqaphsdr5umr9366fum9tigm2.apps.googleusercontent.com',
          clientSecret: '',
          authorize: {
            endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
            responseType: NbOAuth2ResponseType.TOKEN,
            scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',


            redirectUri: 'https://testfe.idgt.it/auth/callback',
          },
        }),
        facebookOauth.setup({
          name: 'facebook',
          clientId: '2935664863372850',
          clientSecret: '0c3d3d413e6aa15a7c94409b3ff2c7bd',
          authorize: {
            endpoint: 'https://www.facebook.com/v3.2/dialog/oauth',
            responseType: NbOAuth2ResponseType.TOKEN,
            scope: 'email',
            redirectUri: '/auth/callbackFB',
          },
        }),
        spidStrategie.setup({
          name: 'spid',
          token: {
            class: NbAuthOAuth2JWTToken,
            key: 'token'
          },
          baseEndpoint: 'fake',  //url base
          login: {
            endpoint: '/auth/loginSPID',  //chiamata al singolo servizio
            method: 'post',      //specificare medoto DEFAULT GET
            redirect: {
              success: 'home', // welcome page path
              failure: null, // stay on the same page
            },
          },
          logout: {
            method: null,
            redirect: {
              success: '/home', // welcome page path
              failure: null, // stay on the same page
            }
          },
          register: {
            // ...
            endpoint: '/auth/register',  //chiamata al singolo servizio
            redirect: {
              success: '/auth/login',
              failure: null, // stay on the same page
            },
          },
        })
      ],

      forms: {},
    }),
    HttpClientModule,
    NbButtonModule,
    NbLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbSpinnerModule,
    NbEvaIconsModule,
    NbCardModule,
    RouterModule,
    NgbModule,
    NgxFileDropModule
  ],
  exports: [TueSegnalazioneComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MyHttpLogInterceptor,
    multi: true
  },locationService,DatePipe, EnvServiceProvider, AuthGuard, facebookOauth, googleOauth, HttpService,spidStrategie, clientStrategie, rivenditoreStrategie,GeocodeLeafletService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    authService: NbAuthService, // force construction of the auth service

    env: EnvService,
  ) {
    authService['strategies'][0]['options']['baseEndpoint'] = env.apiUrl;
    authService['strategies'][1]['options']['baseEndpoint'] = env.apiUrl;
    authService['strategies'][2]['options']['authorize']['redirectUri'] = env.localUrl+"/auth/callback";
    authService['strategies'][3]['options']['authorize']['redirectUri'] = env.localUrl+"/auth/callbackFB";
    authService['strategies'][4]['options']['baseEndpoint'] = env.apiUrl;
  }
}
