export class EnvService {
    // API url
    public apiUrl = 'https://192.168.0.153/apiz'; // default value if env.js is not loaded
    public localUrl = 'http://localhost:4200';
    public gridVisible = false;
    public mapVisible = true;
    public facebookVisible=false;
    public googleVisible=true;
    public spidVisible=false;
    constructor() {
      // console.log("SERVICE ENVIRONMENT STARTING...");
    }

    getUrl()
    {
      return this.apiUrl;
    }

    getGridVisibility() {
      return this.gridVisible;
    }

    getMapVisibility() {
      return this.mapVisible;
    }
    getFacebookVisibility() {
      return this.facebookVisible;
    }

    getGoogleVisibility() {
      return this.googleVisible;
    }

    getSpidVisibility() {
      return this.spidVisible;
    }


  }
