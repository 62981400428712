import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessibilita',
  templateUrl: './accessibilita.component.html',
  styleUrls: ['./accessibilita.component.css']
})
export class AccessibilitaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
